"use client"

import ErrorSection from "@/components/common/ErrorSection";


export default function NotFound() {
  return (
    <div>
      <ErrorSection />
    </div>
  );
}
